import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.getParams();

        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        if(this.params.guests) {
            $('[name=guests]').val(this.params.guests);
            $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);

            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
            
        }

        this.initDepartureCalendar();
        this.setDefaultDeparture();

        this.setGuestsInstances();

        (! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();

        this.getCalculation();

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');

            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

        });

        $('.departure-sm').on('click', e => {
            const $target = $(e.currentTarget);
            this.params.dateFrom = $target.attr('data-datefrom');

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

            functions.scrollToElement('#booking', 0);
        });

        $('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            //this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            //this.getCalculation();
        });

        this.$cart = $('.details-cart');
        this.cart = this.$cart.length ? new DetailsCart(this.params) : [];

        $(window).on('hashchange', e => {
            this.getParams();

            this.setDeparture();
            this.setGuestsInstances();
            (! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();
            this.getCalculation();
        });

        $('.btn-print').on('click', e => {
            const params = $('.btn-print').attr('data-params');

            if(params) {
                const url = location.protocol + '//' + location.hostname + '/services/pdf/' + params;
                window.open(url, '_blank').focus();
            }
        });

        // $('.btn-booking').on('click', e => {
        //     e.preventDefault();

        //     if(! this.cart.cartItems.length) {
        //         $('#detailsCartModal').modal('show');
        //         setTimeout(() => {
        //             $('#detailsCartModal').modal('hide');
        //         }, 2500);
        //     }
        // });

    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-12');
                $('#booking-calendar-slider').addClass('justify-content-xl-center');
                slidesPerView = 8;
                // $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 3));
                // $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 4.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('[data-datefrom]').attr('data-datefrom') : this.params.dateFrom;
        $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
        
        this.slider.instance.moveToIdx($('[data-datefrom=' + this.params.dateFrom + ']').parent().index());
        this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

        this.printDates();
    },
    setDeparture() {
        if(this.params.dateFrom) {
            $('.departure').removeClass('active');
            $('.departure-sm').removeClass('active');

            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');
            
            this.printDates();
        } else {
            this.setDefaultDeparture();
        }
    },
    printDates() {
        $('.date-from').text(functions.formatTravelPeriod(this.params.dateFrom, this.params.dateTo));
        // $('.date-to').text(functions.formatTravelPeriod(this.params.dateTo));
    },
    setUnitRates() {
        if(this.params.dateFrom) {
            this.loader();

            setTimeout(() => {
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-rates]');
                const rates = $departure.attr('data-rates') ? JSON.parse($departure.attr('data-rates')) : null;

                let excludedUnits = [];

                $('.unit').each((index, elem) => {
                    const unitIds = Object.keys(rates);
                    const unitId = $(elem).attr('data-unitid');

                    ! unitIds.includes(unitId) && excludedUnits.push(unitId);
                });

                if(excludedUnits.length) {
                    excludedUnits.forEach(unitId => {
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        $unit.find('.unit-price').addClass('d-none');
                    });
                }

                $('.unit').attr('style', 'order: 10000');
                $('.unit').find('.unit-price-label').addClass('d-none');

                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];

                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    
                    $unit.find('.unit-price').removeClass('d-none');

                    $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                    $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
    
                    $unit.find('.unit-price-old-eur').text(rate.priceWithDiscountEUR ? rate.priceEUR : '');
                    $unit.find('.unit-price-total-eur').text(rate.priceWithDiscountEUR ? rate.priceWithDiscountEUR : rate.priceEUR);
    
                    $unit.find('.period').text(details.params.dateFrom + '-' + details.params.dateTo);
                    $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
                    $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                    $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                    $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                    let price = rate.priceWithDiscount ? rate.priceWithDiscount : rate.price;
                    price = +price.replace('€ ', '');

                    $unit.attr('style', 'order: ' + price);

                    $unit.find('.unit-price-label').removeClass('d-none');
                });
            }, 800);
        }
    },
    setGuestsInstances() {
        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
        
        if(maxPersonsUnits) {
            Object.entries(maxPersonsUnits).forEach(item => {
                const unitId = item[0];
                const quantity = item[1];
    
                $('.input-guests[data-unitid="' + unitId + '"]').each((i, elem) => {
                    const instance = elem._inputGuests; 
                    instance.max = quantity ? quantity : instance.max;
                    instance.checkUpdateBtns(instance, 'adults');
                });
            });
        }
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {

        const calculation = details.getCalculation(this.params);
        if(calculation) {
            this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        } else {

            //this.setPrices($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']'));

            this.setUnitRates();
            
            //$('.btn-booking').toggleClass('d-none', true);
            $('.add-to-cart').toggleClass('d-none', true);
            $('.btn-booking').toggleClass('d-none', true);

            $('.no-period-label').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);

            $('.unit-price-total-label').toggleClass('d-none', true);
            //$('.unit-price-label').toggleClass('d-none', false);

            $('.unit-price-payment-key').toggleClass('d-none', false);

            $('.send-request-label').toggleClass('d-none', true);
        }
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);
            $('.unit').attr('style', 'order: 10000');

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');
                const $addToCart = $unit.find('.add-to-cart');
                const minPersons = +$unit.attr('data-minpersons');
                const maxPersons = +$unit.attr('data-maxpersons');

                let discountTitle = null;

                const unitCalc = unitData.calc;
                let persons = unitCalc.guests.split(',');
                persons = +persons[0] + +persons[1];
                let isBookable = details.isBookable(unitData) && persons >= minPersons && persons <= maxPersons;

                if(isBookable) {
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.fm-discount-title').removeClass('d-none');
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }
                    else
                    {
                        $unit.find('.fm-discount-title').addClass('d-none');
                    }

                    $unit.find('.unit-price-old').text(+priceOld.toFixed(0) > +total.toFixed(0) ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    $unit.prop('style', 'order: ' + parseInt(total));

                    // $addToCart.attr('data-unitcarttotal', total);
                    // $addToCart.attr('data-unitcarttotalold', priceOld);
                    // $addToCart.attr('data-currencysymbol', currencySymbol);

                    // $addToCart.attr('data-datefrom', this.params.dateFrom);
                    // $addToCart.attr('data-dateto', this.params.dateTo);
                    // $addToCart.attr('data-guests', this.params.guests);

                    const $btnBooking = $unit.find('.btn-booking');

                    let cartData = [];

                    cartData.push({
                        'unitId': unitId,
                        'dateFrom': this.params.dateFrom,
                        'dateTo': this.params.dateTo,
                        'guests': this.params.guests,
                    }); 

                    if ($btnBooking.attr('href')) {
                        $btnBooking.attr('href',
                            $btnBooking.attr('href').split('?')[0] +
                            '?unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests + '&objectGroupId=6&addAllOptionals=true'
                        );
                    }

                    // $btnBooking.on('click', e => {
                    //     e.preventDefault();
                    //     let cartData = [];

                    //     this.cart.cartItems.forEach(item => {
                    //         item = JSON.parse(item);
                    //         cartData.push({
                    //             'unitId': item.unitId,
                    //             'dateFrom': item.dateFrom,
                    //             'dateTo': item.dateTo,
                    //             'guests': item.guests,
                    //         }); 
                    //     });

                    //     let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';
                        
                    //     if(this.cart.cartItems.length) {
                    //         location = url;
                    //     } else {
                    //         $('#detailsCartModal').modal('show');
                    //         setTimeout(() => {
                    //             $('#detailsCartModal').modal('hide');
                    //         }, 2500);
                    //     }
                    // });
                    
                }
                
                $unit.find('.send-request-label').toggleClass('d-none', isBookable);
                $unit.find('.unit-price-total-label').toggleClass('d-none', ! isBookable);

                //$unit.find('.unit-price-label').toggleClass('d-none', isBookable);
                $unit.find('.unit-price').toggleClass('d-none', ! isBookable);

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! isBookable);
                $unit.find('.unit-price-payment-key').toggleClass('d-none', isBookable);

                $unit.find('.fm-discount').toggleClass('d-none', ! isBookable);
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', isBookable);
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                $unit.find('.btn-booking').toggleClass('d-none', ! isBookable);

                $addToCart.length && $addToCart.toggleClass('d-none', ! isBookable);

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                if(persons < minPersons) {
                    error = 'Minimalan broj osoba je ' + minPersons + '.';
                } else if(persons > maxPersons) {
                    error = 'Maksimalan broj osoba je ' + maxPersons + '.';
                }
                $unit.find('.unit-error-container').toggleClass('d-none', isBookable);
                $unit.find('.unit-error-container').text(error);

                $unit.find('.no-period-label').toggleClass('d-none', true);
                $unit.find('.unit-price-label').toggleClass('d-none', true);
            });
        }
    },
    loader($target = null) {
        $('.units').addClass('fade');
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
        setTimeout(() => {
            $('.units').removeClass('fade');
        }, 800);
    }
};
